<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-form v-model="detailsFormValid">
          <v-container>
            Contact details
            <v-row>
              <v-col>
                <v-text-field
                  label="First name"
                  v-model="account.firstName"
                  :disabled="detailsDisabled"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Last name"
                  v-model="account.lastName"
                  :disabled="detailsDisabled"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Company"
                  v-model="account.company"
                  :disabled="detailsDisabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Phone"
                  v-model="account.phone"
                  :disabled="detailsDisabled"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Mobile"
                  v-model="account.mobile"
                  :disabled="detailsDisabled"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Email address"
                  v-model="account.email"
                  :disabled="detailsDisabled"
                ></v-text-field>
              </v-col>
            </v-row>
            Saved addresses
            <v-row v-for="(address, a) in account.shippingAddresses" :key="a" style="margin-bottom: 10px;">
              <address-card :address="address" />
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddressCard from '../components/AddressCard.vue';
export default {
  data() {
    return {
      detailsFormValid: false,
      detailsDisabled: true
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  components: {
    AddressCard
  }
};
</script>